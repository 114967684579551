













































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { Global, User, Schedules } from '@/store';
import CurvesList from './CurvesList.vue';
import Calendar from './Calendar.vue';
import CurvesDialog from './CurvesDialog.vue';
import NewCurve from './curves/NewCurve.vue';
import PropEditor from '@/components/PropEditor.vue';
import MessageDialog from '@/components/MessageDialog.vue';
import ConfirmDialog from '@/components/ConfirmDialog.vue';
import { Types } from '@/modules/API';

@Component({
  components: {
    CurvesList,
    CurvesDialog,
    NewCurve,
    Calendar,
    PropEditor,
    MessageDialog,
    ConfirmDialog
  }
})
export default class CurveSelection extends Vue {
    @Global.State('readonly_user') readonly_user;
    @Global.State('lang') lang;
    @User.State('project') project;
    @Schedules.State('userCurves') userCurves;
    @Schedules.State('schedules') schedules;
    @Schedules.Action('deleteCurve') deleteCurve;
    @Schedules.Action('runSchedulerInServer') runSchedulerInServer;

    @Prop() activeCurve;
    @Prop() setActiveCurveData;
    @Prop() setSchedule;
    @Prop() schedule;
    @Prop() updateScheduleCurves;
    @Prop() curves_map;
    @Prop() schedule_curves_map;

    curvesDialog = false;
    selectedCurves = [];
    unselectedCurves = [];
    dataCurve = null;
    curveClicked = false;
    is_days_selected = false;
    deleteInfoDialog = false;
    confirmDeleteDialog = false;
    message = `You must remove this curve from the following schedules before delete`;
    list_info = [];
    delete_curve_id = '';

    mounted(){
        this.setSelectedCurves();
        this.checkDaysSelected();
    }

    @Watch('userCurves')
    setSelectedCurves(){
        if (this.schedule.curves.length){
            this.unselectedCurves = [];
            this.userCurves.forEach((curve) => {
                if (this.schedule_curves_map.has(curve.id)) {
                    const index = this.selectedCurves.findIndex((selected_curve) => selected_curve.id === curve.id);
                    if (index === -1) this.selectedCurves.push(curve);
                }
            });
        }
        const curve_ids = [...this.curves_map.keys()];
        this.selectedCurves = this.selectedCurves.filter((curve) => curve_ids.includes(curve.id));
        this.unselectedCurves = this.userCurves.filter((curve) => this.selectedCurves.findIndex((selected_curve) => selected_curve.id === curve.id) === -1);
    }

    @Watch('schedule.curves')
    checkDaysSelected(){
        this.is_days_selected = this.schedule.curves && this.schedule.curves.length > 0;
    }
    
    removeNotLinkedCurve(curve){
        this.selectedCurves = this.selectedCurves.filter((selected_curve) => selected_curve.id !== curve.id);
        this.unselectedCurves.push(curve);
        if (this.activeCurve.id === curve.id) this.setActiveCurveData({id: '', color: ''});
    }

    checkCurveInUse(curve_id){
        const schedules_in_use = [];
        this.list_info = [];
        this.schedules.forEach((schedule) => {
            if (schedule.id !== this.schedule.id && schedule.curves.some((date_curve) => date_curve.id === curve_id)) {
                schedules_in_use.push(schedule.name);
            }
        });
        this.setDeleteInfoDialog(schedules_in_use.length > 0);
        if (this.deleteInfoDialog) this.list_info = schedules_in_use;
        if (!this.deleteInfoDialog){
            this.confirmDeleteDialog = true;
            this.delete_curve_id = curve_id;
        }
    }

    async deleteSelectedCurve(){
        await this.deleteCurve(this.delete_curve_id);
        this.updateScheduleCurves(this.schedule.curves.filter((curve) => curve.id !== this.delete_curve_id));
        (this.$refs.propEditor as PropEditor).save(this.userCurves, {
                name: 'dashboard.curves',
                objectID: this.project.id,
                objectType: Types.PROJECTS
            }, 
            () => this.runSchedulerInServer(), 
            'Curve Deleted Successfully'
        );
        this.confirmDeleteDialog = false;
        this.delete_curve_id = '';
        this.setCurveClicked(false);
        this.setActiveCurveData({id: '', color: ''});
    }

    setDeleteInfoDialog(is_open){
        this.deleteInfoDialog = is_open;
    }

    updateSelctedCurves(new_selected){
        let new_selected_index = -1;
        // when updating selected curve after create/edit curve
        if (new_selected.length === 1){
            new_selected_index = this.selectedCurves.findIndex((curve) => curve.id === new_selected[0].id);
        }
        this.selectedCurves = new_selected_index > -1
            ? this.selectedCurves.filter((curve) => curve.id !== new_selected[0].id).concat(new_selected)
            : this.selectedCurves.concat(new_selected);
        this.unselectedCurves = this.unselectedCurves.filter((curve) => new_selected.findIndex((new_selected_curve) => new_selected_curve.id === curve.id) === -1);
    }

    setCurveClicked(clicked, id?){
        this.curveClicked = clicked;
        this.setCurveData(id);
    }

    setCurveData(curve_id?){
        if (this.curveClicked){
            this.dataCurve = curve_id
                ? this.userCurves.find((curve) => curve.id === curve_id)
                : null;
        }
    }
}


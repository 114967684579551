














































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { Global, Schedules } from '@/store';
import NewCurve from './curves/NewCurve.vue';
import ConfirmDialog from '@/components/ConfirmDialog.vue';

@Component({ components: {
    NewCurve, 
    ConfirmDialog
}})
export default class CurvesList extends Vue {
    @Prop() activeCurve;
    @Prop() setActiveCurveData;
    @Prop() selectedCurves;
    @Prop() setCurveClicked;
    @Prop() schedule_curves_map;

    @Schedules.State('userCurves') userCurves;
    @Global.State('lang') lang;
    @Global.State('readonly_user') readonly_user;

    hover = null;
    preview = null;

    setPreview(preview) {
        this.preview = preview;
    }

    curveClicked(curve){
        this.activeCurve && this.activeCurve.id === curve.id
            ? this.setActiveCurveData({id: '', color: ''})
            : this.setActiveCurveData(curve);
    }

    checkIsDisabled(curve_id){
        return this.activeCurve.id && this.activeCurve.id !== curve_id;
    }

    onMouseLeave(){
        this.hover = null;
        this.preview = null;
    }
}

